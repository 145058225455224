import React from 'react'
import Layout from '../layouts'
// import { graphql } from 'gatsby'
import CommonHero from '../components/commonhero/commonhero.js'

class buffiniPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'The Buffini Difference' }}>
        <div className="buffini">
          <CommonHero
            className="buffini"
            pageTitle=""
            custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1557988185/brianboals/BRNetwork-1.png"
          />
        </div>
        <div className="py-5 bg-dirty-white">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto col-sm-10 card">
                <h3
                  style={{ color: `#38a7e6` }}
                  className="text-center mb-3 mt-3 pt-3"
                >
                  The Buffini Difference
                </h3>
                <p className="p-2">
                  Working by referral is the concept behind Buffini & Company.
                  One out of every eight homes sold is sold by a Buffini agent.
                  Brian has been involved with Buffini coaching for over 20
                  years. You may ask why this is important to you as a Buyer or
                  Seller. In belonging to the coaching and training programs,
                  Brian meets with a business coach every 2 weeks, a small
                  Denver group of Buffini agents once per month and connects
                  with two other agents in different areas of the country each
                  week. In these efforts, Brian continually grows and improves
                  his own systems and constantly defines his skills to best
                  serve his clients. There are always new things to learn as the
                  real estate industry evolves and this keeps Brian on the
                  cutting edge of information as he trades marketing and
                  negotiating strategies with his colleagues. He has also
                  learned to successfully market his properties through social
                  media. Brian is involved in a Facebook group of top Buffini
                  agents that market their listings online so that agents out of
                  state can also receive information about his listings, which
                  is a great boon for our Sellers. Brian also meets and networks
                  with other agents world-wide, so he has a great referral base
                  if clients are relocating to other areas. Brian consistently
                  attends conferences and seminars for active Realtors, so he
                  gains new concepts to use in his efforts to increase his
                  expertise and knowledge base for his profession.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default buffiniPage
